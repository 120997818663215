import Vue from 'vue';
import VueRouter from 'vue-router';
import VuePageTransition from 'vue-page-transition'
Vue.use(VueRouter);
Vue.use(VuePageTransition)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { transition: 'fade-in-up' },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/projects',
    name: 'Projects',
    meta: { transition: 'fade-in-up' },
    component: () => import('../views/Projects.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { transition: 'fade-in-up' },
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/follow-us',
    name: 'FollowUs',
    meta: { transition: 'fade-in-up' },
    component: () => import('../views/FollowUs.vue'),
  },
  {
    path: '/community',
    name: 'Community',
    meta: { transition: 'fade-in-up' },
    component: () => import('../views/Community.vue'),
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    meta: { transition: 'fade-in-up' },
    component: () => import('../views/ThankYou.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta: { transition: 'fade-in-up' },
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/open-source/py_rankaggregation',
    redirect: () => {
      window.location.pathname = '/py_rankaggregation/index.html'
    }
  },
  // {
  //   path: '/marketing/web',
  //   name: 'Brewing Websites',
  //   meta: { transition: 'fade-in-up' },
  //   component: () => import('../views/WebMarketing.vue'),
  // },
  {
    path: '*',
    name: 'NotFound',
    meta: { transition: 'fade-in-up' },
    component: () => import('../views/NotFound.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
