<template>
  <nav class="navbar">
    <div class="header">
      <router-link to="/" 
        :class="['logo-link', {'hide-logo': scrollPosition > 64}]"
      >
        <img alt="logo" src="../assets/images/global/logo.svg" class="site-logo">
      </router-link>
      <button class="menu-btn" aria-label="Open Menu" @click="toggleMenu">
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="30.667" viewBox="0 0 44 30.667">
          <g id="menu-icon" transform="translate(-529.5 -86.5)">
            <line id="Line_1" data-name="Line 1" x2="40" transform="translate(531.5 88.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
            <line id="Line_2" data-name="Line 2" x2="40" transform="translate(531.5 101.833)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
            <line id="Line_3" data-name="Line 3" x2="40" transform="translate(531.5 115.167)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
          </g>
        </svg>
      </button>
    </div>
    <transition name="fade">
      <div class="menu-content" v-if="showMenu">
        <button class="close-btn" @click="toggleMenu">
          <img alt="menu" src="../assets/images/global/close.svg">
        </button>
        <ul>
          <router-link to="/" @click.native="closeMenu">Services</router-link>
          <router-link to="/projects" @click.native="closeMenu">Projects</router-link>
          <router-link to="/contact" @click.native="closeMenu">Contact</router-link>
          <router-link to="/follow-us" @click.native="closeMenu">Follow Us</router-link>
          <router-link to="/community" @click.native="closeMenu">Community</router-link>
        </ul>
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
      scrollPosition: null,
      currentPath: window.location.pathname,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    toggleMenu(){
      this.showMenu = !this.showMenu;
      if(this.showMenu) {
        document.querySelector('body').style.overflowY = 'hidden';
      }else {
        document.querySelector('body').style.overflowY = 'auto';
      }
    },
    closeMenu() {
      this.showMenu = false
      document.querySelector('body').style.overflowY = 'auto';
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  }
}
</script>
