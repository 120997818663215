<template>
  <footer class="footer">
    <div class="footer-label">
      <span>Designed and coded with</span>
      <img alt="Biscuit" src="../assets/images/global/biscuit.svg" class="footer-icon">
      <span class="plus"> + </span>
      <img alt="Chai" src="../assets/images/global/cup.svg" class="footer-icon">
    </div>
    <p class="fine-print">
    <router-link to="/privacy-policy">Privacy Policy</router-link> | 
    Copyright &copy;  Biscuit &amp; Chai {{ currentYear }}</p>
  </footer>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
}
</script>
